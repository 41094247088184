<template>
  <!-- 发布 -->
  <div>
    <p class="clear" @click="clear">点击清除</p>
  </div>
</template>

<script>
  import {Toast} from "vant";

  export default {
    name: "clear",
    data() {
      return {

      }
    },
    methods: {
      clear() {
        // Toast('该功能已关闭')
        localStorage.clear()
        this.$router.push('/')
      }
    }
  }
</script>
<style scoped lang="less">
.clear{
  color: blue;
  padding: 30px;
  text-align: center;
  }
</style>
